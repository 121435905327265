<template>
  <div class="list" v-html="detail"></div>
</template>

<script>
import { getArticlesDetail } from "@api";
export default {
  name: "ArticlesDetail",
  data() {
    return {
      detail: null,
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      let id = this.$route.query.id;
      getArticlesDetail(id).then(({ data }) => {
        this.detail = data.article_content;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-swipe {
  height: 500px;
}

.tabs ::v-deep .van-tab--active .van-tab__text {
  color: #ee0a24;
}

.list {
  padding-bottom: 100px;
  background: #fff;
  width: 100%;
  &::v-deep video {
    width: 100%;
  }

  &::v-deep img {
    max-width: 100%;
    height: 100%;
  }

  &::v-deep p {
    margin: 0;
  }
  .item {
    padding: 30px 24px;
    border-bottom: 1px dotted #f4f4f4;
    display: flex;
    align-items: center;
    .title {
      flex: 1;
      font-size: 32px;
      color: #222;
      line-height: 48px;
      margin-right: 20px;
    }

    .img-wrap {
      width: 300px;
      height: 200px;
      background: #f4f4f4;
      img {
        display: flex;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.no-data {
  padding-top: 100px;
  height: 500px;
  text-align: center;
  color: #666;
  font-size: 24px;
}
</style>